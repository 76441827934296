@charset "utf-8";

.p-MagIndexLogo {
	position: relative;
	&__items {
		position: absolute;
		bottom: 0;
		right: 35px;
		display: flex;
		justify-content: flex-end;
		// max-width: 108px;
		//		max-width: 120px;
		@include mq(md, max) {
			max-width: 68px;
			flex-wrap: wrap;
			right: 29px;
		}
	}
	&__item {
		flex: 0 0 55px;
		margin: 4px 2px;
		@include mq(md, max) {
			flex-basis: 30px;
		}
		&--reddot {
			flex-basis: 94px;
			@include mq(md, max) {
				flex-basis: 64px;
				margin-bottom: 0;
			}
		}
	}
}

.p-MagScene {
	@include mq(md, min) {
		display: flex;
	}
	@include mq(md, max) {
		max-width: 80%;
		margin-left: auto;
		margin-right: auto;
	}
	&__item {
		position: relative;
		@include mq(md, min) {
			flex: 0 0 33.33%;
		}
	}
	&__text {
		text-align: center;
		@include font-rem(14);
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		max-width: 80%;
		padding: .3em 1em;
		line-height: 1.3;
		color: $color-white;
		background-color: $color-black;
	}
}

.p-Mag {
	position: relative;
	height: auto !important;
	&__inner {
		@include mq(md, max) {
			width: 100%;
			height: 66.66vw;
		}
		@include mq(md, min) {
			padding-top: 0 !important;
			width: 100% !important;
			max-width: 100% !important;
			height: auto !important;
		}
	}
}
.p-magBreadCrumb {
	@include mq(md, min) {
		position: relative;
		width: 87.5%;
		max-width: 70rem;
		margin: auto;
	}
}
.p-MagHeadWrap {
	@include mq(md, min) {
		position: relative;
	}
}
.p-magTextBox {
	//	border: none !important;
}
.p-MagHead {
	@include mq(md, min) {
		position: absolute;
		width: 100%;
	}
	&__logo {
		@include mq(md, min) {
			width: 87.5%;
			max-width: 70rem;
			margin: 0 auto 0;
			padding-top: 80px;
			z-index: 1;
		}
	}
}

.p-MagMv {
	@include mq(md, max) {
		position: absolute;
		inset: 0;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

.p-MagContent {
	@include mq(md, min) {
		width: 87.5%;
		max-width: 70rem;
		margin: auto;
	}
}

.p-Mag01 {
	.accessory .img-wrap {
		height: auto !important;
	}
}

.p-Mag01Head {
	height: auto !important;
}

.p-Mag01Movie {
	@include mq(md, max) {
		position: relative;
		height: 0;
		padding-bottom: 100%;
		overflow: hidden;
		&__item {
			position: absolute;
			height: 100%;
			width: 100%;
			object-fit: cover;
			transform: scale(1.05);
		}
	}
}

.p-Mag01Ppl {
	overflow: hidden;
	padding: 50px;
	border: 2px dashed #1591B7;
	border-radius: 15px;
	@include mq(md, min) {
		display: flex;
		justify-content: space-between;
	}
	@include mq(md, max) {
		padding: 20px;
		border-radius: 8px;
	}
	&__item {
		flex: 0 1 48%;
		&--body {
			padding: 30px;
			background-color: #CAE0E8;
		}
	}
}

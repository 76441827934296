@charset "utf-8";

.buyIt {
  // font-family: Neue Frutiger 1450, sans-serif;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 4.5rem;
  height: 58px;
  font-weight: bold;
  color: $color-white;
  letter-spacing: -0.02em;
  width: 20rem;
  border-radius: 0px;
  line-height: 1.6;
  font-size: 20px;
  background: url(https://talex.co.jp/img/ec/logo_oznis-mark.png) no-repeat left center;
  background-size: 28px;
  background-position: left 16px center;
  background-color: #000000;
  padding-left: 1.5em;
  padding-right: 1.5em;
  &:link,&:visited {
    color: $color-white;
  }
  @include mq(md, max) {
    max-width: 90%;
    margin: auto;
  }
}
// .Button {
//   $root: &;
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   border: 1px solid $color-gray;
//   padding: 10px calc(40px + .8em);
//   min-height: 80px;
//   @include lineHeight-middle;
//   background-color: $color-white;
//   transition: $transition-time-base;
//   @include mq(md, max) {
//     min-height: 60px;
//     padding-right: calc(20px + .8em);
//     padding-left: calc(20px + .8em);
//   }
//   &--round {
//     border-radius: 1000px;
//   }
//   &--large {
//     min-height: 100px;
//     @include mq(md, max) {
//       min-height: 70px;
//       padding-right: calc(20px + .8em);
//       padding-left: calc(20px + .8em);
//     }
//   }
//   &--small {
//     min-height: 60px;
//     padding-right: calc(38px + .8em);
//     padding-left: calc(38px + .8em);
//     @include mq(md, max) {
//       min-height: 50px;
//       padding-right: calc(20px + .8em);
//       padding-left: calc(20px + .8em);
//     }
//   }
//   &--left {
//     padding-left: calc(20px + .8em);
//     align-items: flex-start;
//     @include mq(md, max) {
//       padding-left: calc(10px + .8em);
//     }
//   }
//   &--iconNone {
//     padding-right: calc(20px + .8em);
//     padding-left: calc(20px + .8em);
//     @include mq(md, max) {
//       padding-right: calc(10px + .8em);
//       padding-left: calc(10px + .8em);
//     }
//   }
//   &:not(&--mwNone) {
//     min-width: 340px;
//     @include mq(md, max) {
//       min-width: 205px;
//     }
//   }
//   &:not(&--shadowNone) {
//     box-shadow: 0px 3px 3px 0px rgba($color-gray-light03, 0.80);
//     @include mq(md, max) {
//       box-shadow: 0px 2px 2px 0px rgba($color-gray-light03, 0.80);
//     }
//   }
//   &__icon {
//     position: absolute;
//     display: flex;
//     align-items: center;
//     top: 0;
//     bottom: 0;
//     transition: transform $transition-time-base;
//     margin: auto;
//   }
//   &[href]:hover, &--hover:hover {
//     cursor: pointer;
//     #{$root}__icon:not(#{$root}__icon--noMove) {
//       @include pc {
//         transform: translateX(10px);
//         @include mq(md, max) {
//           transform: translateX(5px);
//         }
//       }
//     }
//     @include pc {
//       border-color: $color-base;
//       background-color: $color-white;
//     }
//   }
//   &:not(#{$root}--prev) {
//     #{$root}__icon {
//       right: 20px;
//       @include mq(md, max) {
//         right: 10px;
//       }
//     }
//   }
//   &--prev {
//     background-color: $color-gray-light;
//     #{$root}__icon {
//       left: 20px;
//       @include mq(md, max) {
//         left: 10px;
//       }
//     }
//     &[href]:hover {
//       #{$root}__icon:not(#{$root}__icon--noMove) {
//         @include pc {
//           transform: translateX(-10px);
//           @include mq(md, max) {
//             transform: translateX(-5px);
//           }
//         }
//       }
//     }
//   }
// }

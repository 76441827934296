@charset "utf-8";
//
.l-inner {
  margin: 0 auto;
  padding: 0 $space-unit;
  @include mq(md, max) {
    padding: 0 $space-unit-mdMax;
  }
}
.l-inner-mdMin {
  @include mq(md, min) {
    margin: 0 auto;
    padding: 0 $space-unit-mdMax;
  }
}
.l-inner-mdMax {
  @include mq(md, max) {
    margin: 0 auto;
    padding: 0 $space-unit;
  }
}
//
.l-flexZero {
  flex: 0 0 auto;
}
.l-flexZero-mdMin {
  @include mq(md, min) {
    flex: 0 0 auto;
  }
}
.l-flexZero-mdMax {
  @include mq(md, max) {
    flex: 0 0 auto;
  }
}
.l-flexGlow {
  flex: 1 0 auto;
}
.l-flexGlow-mdMin {
  @include mq(md, min) {
    flex: 1 0 auto;
  }
}
.l-flexGlow-mdMax {
  @include mq(md, max) {
    flex: 1 0 auto;
  }
}
.l-flexShrink {
  flex: 0 1 auto;
}
.l-flexShrink-mdMin {
  @include mq(md, min) {
    flex: 0 1 auto;
  }
}
.l-flexShrink-mdMax {
  @include mq(md, max) {
    flex: 0 1 auto;
  }
}
.l-flexMax {
  flex: 1 1 auto;
}
.l-flexMax-mdMin {
  @include mq(md, min) {
    flex: 1 1 auto;
  }
}
.l-flexMax-mdMax {
  @include mq(md, max) {
    flex: 1 1 auto;
  }
}
//ベースカラム
.l-column {
  @include column;
  &--long {
    flex-basis: $base-content-width-long;
    max-width: $base-content-width-long;
  }
  &--short {
    flex-basis: $base-content-width-short;
    max-width: $base-content-width-short;
  }
}
//
.l-mwLong {
  max-width: $column-width-long;
  margin: 0 auto;
}
.l-mwShort {
  max-width: $column-width-short;
  margin: 0 auto;
}
.l-mwMax {
  max-width: $column-width-max;
  margin: 0 auto;
}
//
.l-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}
.l-row-mdMin {
  @include mq(md, min) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }
}
//
.l-halfTile {
  width: 50%;
}
//
.l-halfColumn-mdMin {
  @include mq(md, min) {
    @include halfColumn;
    .u-fdRowReverse-mdMin & {
      &:nth-child(1),
      &:last-child {
        margin-right: 0;
      }
      &:nth-child(2) {
        margin-right: $column-space;
      }
    }
  }
}
//
.l-halfBox {
  @include halfColumn;
  @include mq(md, max) {
    @include halfColumn($column-space-mdMax,$column-space-mdMax);
  }
}
.l-halfBox-mdMin {
  @include mq(md, min) {
    @include halfColumn;
  }
}
.l-halfBox-mdMax {
  @include mq(md, max) {
    @include halfColumn($column-space-mdMax,$column-space-mdMax);
  }
}
.l-halfBox-lgMin {
  @include mq(lg, min) {
    @include halfColumn;
  }
}
.l-halfBox-lgMax {
  @include mq(lg, max) {
    @include halfColumn($column-space-lgMax,$column-space-lgMax);
  }
}
//
.l-halfBox02 {
  @include halfColumn($space-unit,$space-unit);
  @include mq(md, max) {
    @include halfColumn($space-unit-mdMax,$space-unit-mdMax);
  }
}
.l-halfBox03 {
  @include halfColumn(10px,5px);
  @include mq(md, max) {
    @include halfColumn(10px,5px);
  }
}
.l-halfBox03-mdMin {
  @include mq(md, min) {
    @include halfColumn(10px,5px);
  }
}
.l-halfBox03-mdMax {
  @include mq(md, max) {
    @include halfColumn(10px,5px);
  }
}

.l-tripartitionBox {
  @include tripartitionColumn;
  @include mq(md, max) {
    @include tripartitionColumn($column-space-mdMax,$column-space-mdMax);
  }
}
.l-tripartitionBox-mdMin {
  @include mq(md, min) {
    @include tripartitionColumn;
  }
}
.l-tripartitionBox-mdMax {
  @include mq(md, max) {
    @include tripartitionColumn($column-space-mdMax,$column-space-mdMax);
  }
}
.l-tripartitionBox-lgMin {
  @include mq(lg, min) {
    @include tripartitionColumn;
  }
}
.l-tripartitionBox-lgMax {
  @include mq(lg, max) {
    @include tripartitionColumn($column-space-lgMax,$column-space-lgMax);
  }
}
.l-tripartitionBox02 {
  @include tripartitionColumn($space-unit,$space-unit);
  @include mq(md, max) {
    @include tripartitionColumn($space-unit-mdMax,$space-unit-mdMax);
  }
}
.l-tripartitionBox02-mdMin {
  @include mq(md, min) {
    @include tripartitionColumn($space-unit,$space-unit);
  }
}

.l-quarterBox-mdMin {
  @include mq(md, min) {
    @include quarterColumn;
  }
}
.l-quarterBox-lgMin {
  @include mq(lg, min) {
    @include quarterColumn;
  }
}
.l-quarterBox-xlMin {
  @include mq(xl, min) {
    @include quarterColumn;
  }
}

.l-fifthBox-mdMin {
  @include mq(md, min) {
    @include fifthColumn;
  }
}
.l-fifthBox-lgMin {
  @include mq(lg, min) {
    @include fifthColumn;
  }
}
.l-fifthBox-xlMin {
  @include mq(xl, min) {
    @include fifthColumn;
  }
}
//
.l-sixthBox-mdMin {
  @include mq(md, min) {
    @include sixthColumn;
  }
}
.l-sixthBox-lgMin {
  @include mq(lg, min) {
    @include sixthColumn;
  }
}
.l-sixthBox-xlMin {
  @include mq(xl, min) {
    @include sixthColumn;
  }
}
//
.l-floatLeft {
  @include mq(md, min) {
    float: left;
    margin-right: $column-space;
  }
  @include mq(md, max) {
    margin-right: $column-space-mdMax;
  }
}
.l-floatLeft-mdMin {
  @include mq(md, min) {
    float: left;
    margin-right: $column-space;
  }
}
.l-floatRight {
  @include mq(md, min) {
    float: right;
    margin-left: $column-space;
  }
  @include mq(md, max) {
    margin-left: $column-space-mdMax;
  }
}
.l-floatRight-mdMin {
  @include mq(md, min) {
    float: right;
    margin-left: $column-space;
  }
}

.l-mw95pct {
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.l-mw90pct {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.l-mw85pct {
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
}
.l-mw80pct {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.l-mw75pct {
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
}
.l-mw70pct {
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}
.l-mw70pct-mdMin {
  @include mq(md, min) {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}
.l-mw70pct-mdMax {
  @include mq(md, max) {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}
.l-mw65pct {
  max-width: 65%;
  margin-left: auto;
  margin-right: auto;
}
.l-mw65pct-mdMin {
  @include mq(md, min) {
    max-width: 65%;
    margin-left: auto;
    margin-right: auto;
  }
}
.l-mw65pct-mdMax {
  @include mq(md, max) {
    max-width: 65%;
    margin-left: auto;
    margin-right: auto;
  }
}
.l-mw60pct {
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
}

@charset "utf-8";

div[id^="product-component-"],
div[id*="product-component-"] {
  max-width: initial !important ;
}

#root.feature-contents section#top .content.golf {
  @include mq(md, min) {
    right: 0;
    left: inherit;
  }
}

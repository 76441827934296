@charset "utf-8";

.bgColorWhite {
	background-color: $color-white;
}
.bgColorBlack {
	background-color: $color-black;
}
.bgColorBlackLight {
	background-color: $color-black-light;
}
.bgColorBase {
	background-color: $color-base;
}
.bgColorGray {
	background-color: $color-gray;
}
.bgColorGrayLight {
	background-color: $color-gray-light;
}
.bgColorGrayLight02 {
	background-color: $color-gray-light02;
}
.bgColorBeige {
	background-color: $color-beige;
}
.bgColorBeigeLight {
	background-color: $color-beige-light;
}

@include mq(md, min) {
	.bgColorGray-mdMin {
		background-color: $color-gray;
	}
	.bgColorGrayLight-mdMin {
		background-color: $color-gray-light;
	}
	.bgColorGrayLight02-mdMin {
		background-color: $color-gray-light02;
	}
}
@include mq(md, max) {
	.bgColorGray-mdMax {
		background-color: $color-gray;
	}
	.bgColorGrayLight-mdMax {
		background-color: $color-gray-light;
	}
	.bgColorGrayLight02-mdMax {
		background-color: $color-gray-light02;
	}
}

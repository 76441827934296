@charset "utf-8";

//text align
.u-center {
  text-align: center;
}
.u-center-mdMin {
  @include mq(md, min) {
    text-align: center;
  }
}
.u-center-mdMax {
  @include mq(md, max) {
    text-align: center;
  }
}
.u-right {
  text-align: right;
}
.u-right-mdMin {
  @include mq(md, min) {
    text-align: right;
  }
}
.u-right-mdMax {
  @include mq(md, max) {
    text-align: right;
  }
}
.u-left {
  text-align: left;
}
.u-left-mdMin {
  @include mq(md, min) {
    text-align: left;
  }
}
.u-left-mdMax {
  @include mq(md, max) {
    text-align: left;
  }
}
.u-asCenter {
  align-self: center;
}
.u-aiCenter {
  align-items: center;
}
.u-aiCenter-mdMin {
  @include mq(md, min) {
    align-items: center;
  }
}
.u-aiCenter-mdMax {
  @include mq(md, max) {
    align-items: center;
  }
}
.u-aiBaseline {
  align-items: baseline;
}
.u-aiBaseline-mdMin {
  @include mq(md, min) {
    align-items: baseline;
  }
}
.u-aiBaseline-mdMax {
  @include mq(md, max) {
    align-items: baseline;
  }
}
.u-jcFlexEnd {
  justify-content: flex-end;
}
.u-jcCenter {
  justify-content: center;
}
.u-jcCenter-mdMin {
  @include mq(md, min) {
    justify-content: center;
  }
}
.u-jcCenter-mdMax {
  @include mq(md, max) {
    justify-content: center;
  }
}
.u-jcSpaceBetween {
  justify-content: space-between;
}
.u-jcSpaceAround {
  justify-content: space-around;
}
.u-fwNoWrap {
  flex-wrap: nowrap;
}
.u-fwNoWrap-mdMin {
  @include mq(md, min) {
    flex-wrap: nowrap;
  }
}
.u-fdRowReverse {
  flex-direction: row-reverse;
}
.u-fdRowReverse-mdMin {
  @include mq(md, min) {
    flex-direction: row-reverse;
  }
}
.u-pdZero {
  padding: 0;
}
.u-pdZero-mdMax {
  @include mq(md, max) {
    padding: 0;
  }
}
.u-pdZero-mdMin {
  @include mq(md, min) {
    padding: 0;
  }
}

//font family
.u-ffGothic {
  @include font-gothic;
}
.u-ffMincho {
  @include font-mincho;
}

//font color
.u-fcBase {
  color: $color-base;
}
.u-fcWhite {
  color: $color-white;
}
.u-fcBlack {
  color: $color-black;
}
.u-fcBlackLight {
  color: $color-black-light;
}
.u-fcGray {
  color: $color-gray;
}
.u-fcGray02 {
  color: $color-gray02;
}

//
.u-fwLight {
  font-weight: 300;
}
.u-fwNormal {
  font-weight: 400;
}
.u-fwBolder {
  font-weight: 500;
}
.u-fwBold {
  font-weight: 700;
}

//font-size　相対的
.u-fsBig01 {
  font-size: 110%;
}
.u-fsBig02 {
  font-size: 120%;
}
.u-fsBig03 {
  font-size: 130%;
}
.u-fsBig04 {
  font-size: 140%;
}
.u-fsBig05 {
  font-size: 150%;
}
.u-fsBig06 {
  font-size: 160%;
}
.u-fsBig07 {
  font-size: 170%;
}
.u-fsBig08 {
  font-size: 180%;
}
.u-fsBig09 {
  font-size: 190%;
}
.u-fsBig010 {
  font-size: 200%;
}
.u-fsSmall01 {
  font-size: 95%;
}
.u-fsSmall02 {
  font-size: 90%;
}
.u-fsSmall03 {
  font-size: 85%;
}
.u-fsSmall04 {
  font-size: 80%;
}
.u-fsSmall05 {
  font-size: 75%;
}
.u-fsSmall06 {
  font-size: 70%;
}
.u-fsSmall07 {
  font-size: 65%;
}
.u-fsSmall08 {
  font-size: 60%;
}
.u-fsSmall09 {
  font-size: 55%;
}
.u-fsSmall010 {
  font-size: 50%;
}
//
.u-ocZero {
  opacity: 0;
}
//行間
.u-lhZero {
  @include lineHeight-zero;
}
.u-lhLow {
  @include lineHeight-low;
}
.u-lhMiddle {
  @include lineHeight-middle;
}
.u-lhHigh {
  @include lineHeight-high;
}
.u-lhHighest {
  @include lineHeight-highest;
}

//字間
.u-lsZero {
  letter-spacing: 0;
}
.u-lsShort {
  @include letterSpace-short;
}
.u-lsNormal {
  @include letterSpace-normal;
}
.u-lsLong {
  @include letterSpace-long;
}

//
.u-ofHidden {
  overflow: hidden;
}
.u-ofHidden-mdMin {
  @include mq(md, min) {
    overflow: hidden;
  }
}
.u-ofHidden-mdMax {
  @include mq(md, max) {
    overflow: hidden;
  }
}
.u-dpBlock {
display: block;
}
.u-dpInlineBlock {
  display: inline-block;
}

/////////////////////////////////
/////////////////////////////////
/////////////////////////////////
/////////////////////////////////
/////////////////////////////////
/////////////////////////////////
//svg-fill
//.u-fillWhite {
//  fill: $color-white;
//}
//.u-fillBlack {
//  fill: $color-black;
//}

//.u-fcRed {
//  color: $color-base;
//}
//.u-fcYellow {
//  color: $color-yellow;
//}





/*
.u-tdUnderLine {
text-decoration: underline;
}
//
.u-fsItalic {
font-style: italic;
}

//
.u-radiusSmall {
border-radius: 5px;
@include mq(md, max) {
border-radius: 3px;
}
}
.u-radiusMedium {
border-radius: 10px;
@include mq(md, max) {
border-radius: 5px;
}
}
.u-radiusLarge {
border-radius: 15px;
@include mq(md, max) {
border-radius: 8px;
}
}
.u-vaMiddle {
vertical-align: middle;
}


*/

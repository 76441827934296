@charset "utf-8";

// break point
$mq-breakpoints: (
// 'xs': 321px,
'sm': 576px,
'md': 768px,
'lg': 1024px,
'xl': 1280px,
//'col': $base-content-width,
'colLong': $base-content-width-long,
// 'xxl': $column-width-max,
) !default;
@mixin mq($breakpoint: md, $rule: min) {
  $breakpoint: map-get($mq-breakpoints, $breakpoint);
  @if $rule == max {
    $breakpoint: $breakpoint - 1px;
  }
  @media screen and (#{$rule}-width: $breakpoint) {
    @content;
  }
}
@mixin mqMinMax($breakpointMin: md, $breakpointMax: lg) {
  $breakpointMin: map-get($mq-breakpoints, $breakpointMin);
  $breakpointMax: map-get($mq-breakpoints, $breakpointMax);
  $breakpointMax: $breakpointMax - 1px;
  @media screen and (min-width: $breakpointMin) and (max-width: $breakpointMax) {
    @content;
  }
}

// font size
@mixin font-rem($fontSize-px) {
  font-size: $fontSize-px / $root-fontSize * 1rem;
}
@mixin font-clamp($fontSize-min,$fontSize-vw,$fontSize-max) {
  font-size: clamp($fontSize-min / $root-fontSize * 1rem,$fontSize-vw * 1vw,$fontSize-max / $root-fontSize * 1rem);
}

// user agent
@mixin pc() {
  .desktop & {
    @content;
  }
}
@mixin sp() {
  .smartphone & {
    @content;
  }
}
@mixin tab() {
  .tablet & {
    @content;
  }
}

// font family
@mixin font-gothic() {
  font-family: $font-gothic;
}
@mixin font-mincho() {
  font-family: $font-mincho;
}
//@mixin font-english() {
//  font-family: $font-english;
//}

// メインのカラムサイズ
@mixin column($width:$base-content-width) {
  flex: 1 1 $width;
  margin: 0 auto;
  @include mq(md, min) {
    max-width: $width;
    padding: 0 $space-unit;
  }
  @include mq(md, max) {
    padding: 0 $space-unit-mdMax;
  }
}

//カラム
@mixin oneColumn($space:$column-space) {
  $root: &;
  flex: 1 1 100%;
  max-width: 100%;
  margin-right: 0;
  &:not(:last-child) {
    margin-bottom: $space;
  }
}

@mixin halfColumn($space:$column-space,$space-bottom:$column-space) {
  $root: &;
  flex: 1 1 calc(50% - #{$space/2});
  max-width: calc(50% - #{$space/2});
  margin-right: $space;
  margin-bottom: $space-bottom;
  &:nth-child(2n),
  &:last-child {
    margin-right: 0;
  }
  &:last-child,
  &:nth-last-child(2)#{$root}:nth-child(2n+1) {
    margin-bottom: 0;
  }
}

@mixin tripartitionColumn($space:$column-space,$space-bottom:$column-space) {
  $root: &;
  flex: 1 1 calc(33.33% - #{$space*2/3});
  max-width: calc(33.33% - #{$space*2/3});
  margin-right: $space;
  margin-bottom: $space-bottom;
  &:nth-child(3n),
  &:last-child {
    margin-right: 0;
  }
  &:last-child,
  &:nth-last-child(2)#{$root}:nth-child(3n+2),
  &:nth-last-child(2)#{$root}:nth-child(3n+1),
  &:nth-last-child(3)#{$root}:nth-child(3n+1) {
    margin-bottom: 0;
  }
}

@mixin quarterColumn($space:$column-space/2,$space-bottom:$column-space/2) {
  $root: &;
  flex: 1 1 calc(25.00% - #{$space*3/4});
  max-width: calc(25.00% - #{$space*3/4});
  margin-right: $space;
  margin-bottom: $space-bottom;
  &:nth-child(4n),
  &:last-child {
    margin-right: 0;
  }
  &:last-child,
  &:nth-last-child(2)#{$root}:nth-child(4n+1),
  &:nth-last-child(2)#{$root}:nth-child(4n+2),
  &:nth-last-child(2)#{$root}:nth-child(4n+3),
  &:nth-last-child(3)#{$root}:nth-child(4n+1),
  &:nth-last-child(3)#{$root}:nth-child(4n+2),
  &:nth-last-child(4)#{$root}:nth-child(4n+1) {
    margin-bottom: 0;
  }
}

@mixin fifthColumn($space:$column-space/2,$space-bottom:$column-space/2) {
  $root: &;
  flex: 1 1 calc(20.00% - #{$space*4/5});
  max-width: calc(20.00% - #{$space*4/5});
  margin-right: $space;
  margin-bottom: $space-bottom;
  &:nth-child(5n),
  &:last-child {
    margin-right: 0;
  }
  &:last-child,
  &:nth-last-child(2)#{$root}:nth-child(5n+1),
  &:nth-last-child(2)#{$root}:nth-child(5n+2),
  &:nth-last-child(2)#{$root}:nth-child(5n+3),
  &:nth-last-child(2)#{$root}:nth-child(5n+4),
  &:nth-last-child(3)#{$root}:nth-child(5n+1),
  &:nth-last-child(3)#{$root}:nth-child(5n+2),
  &:nth-last-child(3)#{$root}:nth-child(5n+3),
  &:nth-last-child(4)#{$root}:nth-child(5n+1),
  &:nth-last-child(4)#{$root}:nth-child(5n+2),
  &:nth-last-child(5)#{$root}:nth-child(5n+1) {
    margin-bottom: 0;
  }
}

@mixin sixthColumn($space:$column-space/2,$space-bottom:$column-space/2) {
  $root: &;
  flex: 1 1 calc(16.66% - #{$space*5/6});
  max-width: calc(16.66% - #{$space*5/6});
  margin-right: $space;
  margin-bottom: $space-bottom;
  &:nth-child(6n),
  &:last-child {
    margin-right: 0;
  }
  &:last-child,
  &:nth-last-child(2)#{$root}:nth-child(6n+1),
  &:nth-last-child(2)#{$root}:nth-child(6n+2),
  &:nth-last-child(2)#{$root}:nth-child(6n+3),
  &:nth-last-child(2)#{$root}:nth-child(6n+4),
  &:nth-last-child(2)#{$root}:nth-child(6n+5),
  &:nth-last-child(3)#{$root}:nth-child(6n+1),
  &:nth-last-child(3)#{$root}:nth-child(6n+2),
  &:nth-last-child(3)#{$root}:nth-child(6n+3),
  &:nth-last-child(3)#{$root}:nth-child(6n+4),
  &:nth-last-child(4)#{$root}:nth-child(6n+1),
  &:nth-last-child(4)#{$root}:nth-child(6n+2),
  &:nth-last-child(4)#{$root}:nth-child(6n+3),
  &:nth-last-child(5)#{$root}:nth-child(6n+1),
  &:nth-last-child(5)#{$root}:nth-child(6n+2),
  &:nth-last-child(6)#{$root}:nth-child(6n+1) {
    margin-bottom: 0;
  }
}

//clear fix
@mixin cf() {
  *zoom: 1;
  &::before,
  &::after {
    content: " ";
    display: table;
  }
  &::after {
    clear: both;
  }
}

//line height　調整用
@mixin lineHeight-zero() {
  line-height: 1.0;
}
@mixin lineHeight-low() {
  line-height: $line-height-low;
}
@mixin lineHeight-middle() {
  line-height: $line-height-middle;
}
@mixin lineHeight-normal() {
  line-height: $line-height;
}
@mixin lineHeight-high() {
  line-height: $line-height-high;
}
@mixin lineHeight-highest() {
  line-height: $line-height-highest;
}
//letter spacing　調整用
@mixin letterSpace-short() {
  letter-spacing: .05em;
}
@mixin letterSpace-normal() {
  letter-spacing: .1em;
}
@mixin letterSpace-long() {
  letter-spacing: .15em;
}

//矢印
@mixin arrow($width: 21px,$height: 21px,$width-mdMax: 15px,$height-mdMax: 15px,$color: $color-base,$border: 3px,$border-mdMax: 2px) {
  display: inline-block;
  width: $width;
  height: $height;
  border-style: solid;
  border-width: $border $border 0 0;
  border-color: $color $color transparent transparent;
  vertical-align: middle;
  letter-spacing: normal;
  transform: translateX(-25%) rotate(45deg);
  transform-origin: center;
  transition: .3s;
  @include mq(md, max) {
    width: $width-mdMax;
    height: $height-mdMax;
    border-width: $border-mdMax $border-mdMax 0 0;
  }
}

@mixin bgGrad-half($left-color:$color-gray-light,$right-color:$color-gray,$width-ratio:50%) {
  background: linear-gradient(90deg, $left-color 0%, $left-color $width-ratio, $right-color $width-ratio, $right-color 100%);
}

//@mixin arrow2($width: 9px,$height: 9px,$color: $color-black,$rorate: 45deg,$translateY: 0,$border: 1px) {
//  overflow: hidden;
//  position: relative;
//  font-size: 0;
//  padding: 0;
//  transition: .3s;
//  display: inline-block;
//  width: $width;
//  height: $height;
//  border: $border solid;
//  border-color: $color $color transparent transparent;
//  transform: translateY($translateY) rotate($rorate);
//  &::after {
//    position: absolute;
//    right: 0;
//    top: -1px;
//    margin: auto;
//    content: "";
//    height: 1px;
//    width: 10px;
//    transform: rotate(-45deg);
//    transform-origin: right top;
//    background-color: $color-black;
//  }
//}

//グラデーション
/**/
//$base-gradient: linear-gradient(-22deg, $color-gradOrange1 0%, $color-gradOrange2 25%, $color-gradOrange3 50%, $color-gradOrange4 75%, $color-gradOrange5 100%);
//@mixin bg-grad() {
//  background-color: $color-base;
//  background-image: $base-gradient;
//  background-position: center;
//  background-size: 250% 100%;
//}
//$base-instagram: linear-gradient(225deg,$color-instagram 0%,#f50000 100%);
//@mixin bg-grad-instagram() {
//  background-color: $color-instagram;
//  background-image: $base-instagram;
//  background-position: center;
//  background-size: 100% 100%;
//}

//テキストグラデーション
//@mixin text-grad() {
//  @include bg-grad;
//  -webkit-background-clip: text;
//  -webkit-text-fill-color: transparent;
//}
//@mixin text-grad-instagram() {
//  @include bg-grad-instagram;
//  -webkit-background-clip: text;
//  -webkit-text-fill-color: transparent;
//}

//ここまで
//@mixin border-grad($width: 1px) {
//  border-left: $width solid $color-gradOrange1;
//  border-right: $width solid $color-gradOrange5;
//  border-bottom: $width solid;
//  border-top: $width solid;
//  border-image: $base-gradient;
//  border-image-slice: 1;
//}

//三角
//@mixin triangle($width: 5px,$color: $color-black,$rorate: 0deg) {
//  display: inline-block;
//  width: 0;
//  height: 0;
//  border-style: solid;
//  border-width: $width 0 $width $width*1.732;
//  border-color: transparent transparent transparent $color;
//  transform: rotate($rorate);
//}

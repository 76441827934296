@charset "utf-8";

$smallText-mdMin: 10;
$smallText-mdMax: 12;
.smallText {
  @include font-rem($smallText-mdMin);
  @include mq(md, max) {
    @include font-rem($smallText-mdMax);
  }
}

$smallText02-mdMin: 12;
$smallText02-mdMax: $smallText-mdMax;
.smallText02 {
  @include font-rem($smallText02-mdMin);
  @include mq(md, max) {
    @include font-rem($smallText02-mdMax);
  }
}

$smallText03-mdMin: 13;
$smallText03-mdMax: $smallText-mdMax;
.smallText03 {
  @include font-rem($smallText03-mdMin);
  @include mq(md, max) {
    @include font-rem($smallText03-mdMax);
  }
}

$mediumText-mdMin: 14;
$mediumText-mdMax: 13;
.mediumText {
  @include font-rem($mediumText-mdMin);
  @include mq(md, max) {
    @include font-rem($mediumText-mdMax);
  }
}

$mediumText02-mdMin: 15;
$mediumText02-mdMax: $mediumText-mdMax;
.mediumText02 {
  @include font-rem($mediumText02-mdMin);
  @include mq(md, max) {
    @include font-rem($mediumText02-mdMax);
  }
}

$normalText-mdMin: 16;
$normalText-mdMax: 14;
.normalText {//////////////
  @include font-rem($normalText-mdMin);
  @include mq(md, max) {
    @include font-rem($normalText-mdMax);
  }
}

$normalText02-mdMin: 17;
$normalText02-mdMax: $normalText-mdMax;
.normalText02 {
  @include font-rem($normalText02-mdMin);
  @include mq(md, max) {
    @include font-rem($normalText02-mdMax);
  }
}

$largeText-mdMin: 18;
$largeText-mdMax: 16;
.largeText {
  @include font-rem($largeText-mdMin);
  @include mq(md, max) {
    @include font-rem($largeText-mdMax);
  }
}

$largeText02-mdMin: 19;
$largeText02-mdMax: $largeText-mdMax;
.largeText02 {
  @include font-rem($largeText02-mdMin);
  @include mq(md, max) {
    @include font-rem($largeText02-mdMax);
  }
}

$largeText03-mdMin: 20;
$largeText03-mdMax: $largeText-mdMax;
.largeText03 {
  @include font-rem($largeText03-mdMin);
  @include mq(md, max) {
    @include font-rem($largeText03-mdMax);
  }
}

$exLargeText-mdMin: 21;
$exLargeText-mdMax: 18;
.exLargeText {
  @include font-rem($exLargeText-mdMin);
  @include mq(md, max) {
    @include font-rem($exLargeText-mdMax);
  }
}

$exLargeText02-mdMin: 22;
$exLargeText02-mdMax: $exLargeText-mdMax;
.exLargeText02 {
  @include font-rem($exLargeText02-mdMin);
  @include mq(md, max) {
    @include font-rem($exLargeText02-mdMax);
  }
}

$xxLargeText-mdMin: 24;
$xxLargeText-mdMax: 20;
.xxLargeText {
  @include font-rem($xxLargeText-mdMin);
  @include mq(md, max) {
    @include font-rem($xxLargeText-mdMax);
  }
}

$xxLargeText02-mdMin: 28;
$xxLargeText02-mdMax: $xxLargeText-mdMax;
.xxLargeText02 {
  @include font-rem($xxLargeText02-mdMin);
  @include mq(md, max) {
    @include font-rem($xxLargeText02-mdMax);
  }
}

$xxxLargeText-mdMin: 32;
$xxxLargeText-mdMax: 22;
.xxxLargeText {
  @include font-rem($xxxLargeText-mdMin);
  @include mq(md, max) {
    @include font-rem($xxxLargeText-mdMax);
  }
}

$xxxLargeText02-mdMin: 36;
$xxxLargeText02-mdMax: $xxxLargeText-mdMax;
.xxxLargeText02 {
  @include font-rem($xxxLargeText02-mdMin);
  @include mq(md, max) {
    @include font-rem($xxxLargeText02-mdMax);
  }
}

@include mq(md, min) {
  .smallText-mdMin {
    @include font-rem($smallText-mdMin);
  }
  .smallText02-mdMin {
    @include font-rem($smallText02-mdMin);
  }
  .smallText03-mdMin {
    @include font-rem($smallText03-mdMin);
  }
  .mediumText-mdMin {
    @include font-rem($mediumText-mdMin);
  }
  .mediumText02-mdMin {
    @include font-rem($mediumText02-mdMin);
  }
  .normalText-mdMin {//////////////
    @include font-rem($normalText-mdMin);
  }
  .normalText02-mdMin {
    @include font-rem($normalText02-mdMin);
  }
  .largeText-mdMin {
    @include font-rem($largeText-mdMin);
  }
  .largeText02-mdMin {
    @include font-rem($largeText02-mdMin);
  }
  .largeText03-mdMin {
    @include font-rem($largeText03-mdMin);
  }
  .exLargeText-mdMin {
    @include font-rem($exLargeText-mdMin);
  }
  .exLargeText02-mdMin {
    @include font-rem($exLargeText02-mdMin);
  }
  .xxLargeText-mdMin {
    @include font-rem($xxLargeText-mdMin);
  }
  .xxLargeText02-mdMin {
    @include font-rem($xxLargeText02-mdMin);
  }
  .xxxLargeText-mdMin {
    @include font-rem($xxxLargeText-mdMin);
  }
  .xxxLargeText02-mdMin {//////////////
    @include font-rem($xxxLargeText02-mdMin);
  }
}

@include mq(md, max) {
  .smallText-mdMax {
    @include font-rem($smallText-mdMax);
  }
//  .smallText02-mdMax {
//    @include font-rem($smallText02-mdMax);
//  }
//  .smallText03-mdMax {
//    @include font-rem($smallText03-mdMax);
//  }
  .mediumText-mdMax {
    @include font-rem($mediumText-mdMax);
  }
//  .mediumText02-mdMax {
//    @include font-rem($mediumText02-mdMax);
//  }
  .normalText-mdMax {//////////////
    @include font-rem($normalText-mdMax);
  }
//  .normalText02-mdMax {
//    @include font-rem($normalText02-mdMax);
//  }
  .largeText-mdMax {
    @include font-rem($largeText-mdMax);
  }
//  .largeText02-mdMax {
//    @include font-rem($largeText02-mdMax);
//  }
//  .largeText03-mdMax {
//    @include font-rem($largeText03-mdMax);
//  }
  .exLargeText-mdMax {
    @include font-rem($exLargeText-mdMax);
  }
//  .exLargeText02-mdMax {
//    @include font-rem($exLargeText02-mdMax);
//  }
  .xxLargeText-mdMax {
    @include font-rem($xxLargeText-mdMax);
  }
//  .xxLargeText02-mdMax {
//    @include font-rem($xxLargeText02-mdMax);
//  }
  .xxxLargeText-mdMax {
    @include font-rem($xxxLargeText-mdMax);
  }
//  .xxxLargeText02-mdMax {//////////////
//    @include font-rem($xxxLargeText02-mdMax);
//  }
}

@charset "utf-8";

.hr {
  border: none;
  border-color: $color-black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin: 0;
  &--dotted {
    border-bottom-style: dotted;
  }
  &--dashed {
    border-bottom-style: dashed;
  }
  &--gray {
    border-color: $color-gray;
  }
  &--gray02 {
    border-color: $color-gray02;
  }
}

@charset "utf-8";

$auto-space: auto;
$auto-space-mdMax: auto;
.box-auto {
  margin: $auto-space;
  @include mq(md, max) {
    margin: $auto-space-mdMax;
  }
}
.block-auto {
  margin-top: $auto-space;
  margin-bottom: $auto-space;
  @include mq(md, max) {
    margin-top: $auto-space-mdMax;
    margin-bottom: $auto-space-mdMax;
  }
}
.blockT-auto {
  margin-top: $auto-space;
  @include mq(md, max) {
    margin-top: $auto-space-mdMax;
  }
}
.blockB-auto {
  margin-bottom: $auto-space;
  @include mq(md, max) {
    margin-bottom: $auto-space;
  }
}

$base-space: $space-unit;
$base-space-mdMax: $space-unit-mdMax;
.box-base {
  margin: $base-space;
  @include mq(md, max) {
    margin: $base-space-mdMax;
  }
}
.block-base {
  margin-top: $base-space;
  margin-bottom: $base-space;
  @include mq(md, max) {
    margin-top: $base-space-mdMax;
    margin-bottom: $base-space-mdMax;
  }
}
.blockT-base {
  margin-top: $base-space;
  @include mq(md, max) {
    margin-top: $base-space-mdMax;
  }
}
.blockR-base {
  margin-right: $base-space;
  @include mq(md, max) {
    margin-right: $base-space-mdMax;
  }
}
.blockB-base {
  margin-bottom: $base-space;
  @include mq(md, max) {
    margin-bottom: $base-space-mdMax;
  }
}
.blockL-base {
  margin-left: $base-space;
  @include mq(md, max) {
    margin-left: $base-space-mdMax;
  }
}
$section03-space: 150px;
$section03-space-mdMax: 100px;
.box-section03 {
  margin: $section03-space;
  @include mq(md, max) {
    margin: $section03-space-mdMax;
  }
}
.block-section03 {
  margin-top: $section03-space;
  margin-bottom: $section03-space;
  @include mq(md, max) {
    margin-top: $section03-space-mdMax;
    margin-bottom: $section03-space-mdMax;
  }
}
.blockT-section03 {
  margin-top: $section03-space;
  @include mq(md, max) {
    margin-top: $section03-space-mdMax;
  }
}
.blockB-section03 {
  margin-bottom: $section03-space;
  @include mq(md, max) {
    margin-bottom: $section03-space-mdMax;
  }
}

$section02-space: 120px;
$section02-space-mdMax: 75px;
.box-section02 {
  margin: $section02-space;
  @include mq(md, max) {
    margin: $section02-space-mdMax;
  }
}
.block-section02 {
  margin-top: $section02-space;
  margin-bottom: $section02-space;
  @include mq(md, max) {
    margin-top: $section02-space-mdMax;
    margin-bottom: $section02-space-mdMax;
  }
}
.blockT-section02 {
  margin-top: $section02-space;
  @include mq(md, max) {
    margin-top: $section02-space-mdMax;
  }
}
.blockB-section02 {
  margin-bottom: $section02-space;
  @include mq(md, max) {
    margin-bottom: $section02-space-mdMax;
  }
}

$section-space: 100px;
$section-space-mdMax: 50px;
.box-section {
  margin: $section-space;
  @include mq(md, max) {
    margin: $section-space-mdMax;
  }
}
.block-section {
  margin-top: $section-space;
  margin-bottom: $section-space;
  @include mq(md, max) {
    margin-top: $section-space-mdMax;
    margin-bottom: $section-space-mdMax;
  }
}
.blockT-section {
  margin-top: $section-space;
  @include mq(md, max) {
    margin-top: $section-space-mdMax;
  }
}
.blockB-section {
  margin-bottom: $section-space;
  @include mq(md, max) {
    margin-bottom: $section-space-mdMax;
  }
}

$contents03-space: 60px;
$contents03-space-mdMax: 40px;
.box-contents03 {
  margin: $contents03-space;
  @include mq(md, max) {
    margin: $contents03-space-mdMax;
  }
}
.block-contents03 {
  margin-top: $contents03-space;
  margin-bottom: $contents03-space;
  @include mq(md, max) {
    margin-top: $contents03-space-mdMax;
    margin-bottom: $contents03-space-mdMax;
  }
}
.blockT-contents03 {
  margin-top: $contents03-space;
  @include mq(md, max) {
    margin-top: $contents03-space-mdMax;
  }
}
.blockR-contents03  {
  margin-right: $contents03-space;
  @include mq(md, max) {
    margin-right: $contents03-space-mdMax;
  }
}
.blockB-contents03  {
  margin-bottom: $contents03-space;
  @include mq(md, max) {
    margin-bottom: $contents03-space-mdMax;
  }
}
.blockL-contents03  {
  margin-left: $contents03-space;
  @include mq(md, max) {
    margin-left: $contents03-space-mdMax;
  }
}

$contents02-space: 50px;
$contents02-space-mdMax: 35px;
.box-contents02 {
  margin: $contents02-space;
  @include mq(md, max) {
    margin: $contents02-space-mdMax;
  }
}
.block-contents02 {
  margin-top: $contents02-space;
  margin-bottom: $contents02-space;
  @include mq(md, max) {
    margin-top: $contents02-space-mdMax;
    margin-bottom: $contents02-space-mdMax;
  }
}
.blockT-contents02 {
  margin-top: $contents02-space;
  @include mq(md, max) {
    margin-top: $contents02-space-mdMax;
  }
}
.blockR-contents02 {
  margin-right: $contents02-space;
  @include mq(md, max) {
    margin-right: $contents02-space-mdMax;
  }
}
.blockB-contents02 {
  margin-bottom: $contents02-space;
  @include mq(md, max) {
    margin-bottom: $contents02-space-mdMax;
  }
}
.blockL-contents02 {
  margin-left: $contents02-space;
  @include mq(md, max) {
    margin-left: $contents02-space-mdMax;
  }
}

$contents-space: 40px;
$contents-space-mdMax: 30px;
.box-contents {
  margin: $contents-space;
  @include mq(md, max) {
    margin: $contents-space-mdMax;
  }
}
.block-contents {
  margin-top: $contents-space;
  margin-bottom: $contents-space;
  @include mq(md, max) {
    margin-top: $contents-space-mdMax;
    margin-bottom: $contents-space-mdMax;
  }
}
.blockT-contents {
  margin-top: $contents-space;
  @include mq(md, max) {
    margin-top: $contents-space-mdMax;
  }
}
.blockR-contents {
  margin-right: $contents-space;
  @include mq(md, max) {
    margin-right: $contents-space-mdMax;
  }
}
.blockB-contents {
  margin-bottom: $contents-space;
  @include mq(md, max) {
    margin-bottom: $contents-space-mdMax;
  }
}
.blockL-contents {
  margin-left: $contents-space;
  @include mq(md, max) {
    margin-left: $contents-space-mdMax;
  }
}

$normal-space: 30px;
$normal-space-mdMax: 20px;
.box-normal {
  margin: $normal-space;
  @include mq(md, max) {
    margin: $normal-space-mdMax;
  }
}
.block-normal {
  margin-top: $normal-space;
  margin-bottom: $normal-space;
  @include mq(md, max) {
    margin-top: $normal-space-mdMax;
    margin-bottom: $normal-space-mdMax;
  }
}
.blockT-normal {
  margin-top: $normal-space;
  @include mq(md, max) {
    margin-top: $normal-space-mdMax;
  }
}
.blockR-normal {
  margin-right: $normal-space;
  @include mq(md, max) {
    margin-right: $normal-space-mdMax;
  }
}
.blockB-normal {
  margin-bottom: $normal-space;
  @include mq(md, max) {
    margin-bottom: $normal-space-mdMax;
  }
}
.blockL-normal {
  margin-left: $normal-space;
  @include mq(md, max) {
    margin-left: $normal-space-mdMax;
  }
}

$semi-space: 20px;
$semi-space-mdMax: 15px;
.box-semi {
  margin: $semi-space;
  @include mq(md, max) {
    margin: $semi-space-mdMax;
  }
}
.block-semi {
  margin-top: $semi-space;
  margin-bottom: $semi-space;
  @include mq(md, max) {
    margin-top: $semi-space-mdMax;
    margin-bottom: $semi-space-mdMax;
  }
}
.blockT-semi {
  margin-top: $semi-space;
  @include mq(md, max) {
    margin-top: $semi-space-mdMax;
  }
}
.blockR-semi {
  margin-right: $semi-space;
  @include mq(md, max) {
    margin-right: $semi-space-mdMax;
  }
}
.blockB-semi {
  margin-bottom: $semi-space;
  @include mq(md, max) {
    margin-bottom: $semi-space-mdMax;
  }
}
.blockL-semi {
  margin-left: $semi-space;
  @include mq(md, max) {
    margin-left: $semi-space-mdMax;
  }
}

$small-space: 15px;
$small-space-mdMax: 10px;
.box-small {
  margin: $small-space;
  @include mq(md, max) {
    margin: $small-space-mdMax;
  }
}
.block-small {
  margin-top: $small-space;
  margin-bottom: $small-space;
  @include mq(md, max) {
    margin-top: $small-space-mdMax;
    margin-bottom: $small-space-mdMax;
  }
}
.blockT-small {
  margin-top: $small-space;
  @include mq(md, max) {
    margin-top: $small-space-mdMax;
  }
}
.blockR-small {
  margin-right: $small-space;
  @include mq(md, max) {
    margin-right: $small-space-mdMax;
  }
}
.blockB-small {
  margin-bottom: $small-space;
  @include mq(md, max) {
    margin-bottom: $small-space-mdMax;
  }
}
.blockL-small {
  margin-left: $small-space;
  @include mq(md, max) {
    margin-left: $small-space-mdMax;
  }
}

$mini-space: 10px;
$mini-space-mdMax: 10px;
.box-mini {
  margin: $mini-space;
  @include mq(md, max) {
    margin: $mini-space-mdMax;
  }
}
.block-mini {
  margin-top: $mini-space;
  margin-bottom: $mini-space;
  @include mq(md, max) {
    margin-top: $mini-space-mdMax;
    margin-bottom: $mini-space-mdMax;
  }
}
.blockT-mini {
  margin-top: $mini-space;
  @include mq(md, max) {
    margin-top: $mini-space-mdMax;
  }
}
.blockR-mini {
  margin-right: $mini-space;
  @include mq(md, max) {
    margin-right: $mini-space-mdMax;
  }
}
.blockB-mini {
  margin-bottom: $mini-space;
  @include mq(md, max) {
    margin-bottom: $mini-space-mdMax;
  }
}
.blockL-mini {
  margin-left: $mini-space;
  @include mq(md, max) {
    margin-left: $mini-space-mdMax;
  }
}

$minimum-space: 5px;
$minimum-space-mdMax: 5px;
.box-minimum {
  margin: $minimum-space;
  @include mq(md, max) {
    margin: $minimum-space-mdMax;
  }
}
.block-minimum {
  margin-top: $minimum-space;
  margin-bottom: $minimum-space;
  @include mq(md, max) {
    margin-top: $minimum-space-mdMax;
    margin-bottom: $minimum-space-mdMax;
  }
}
.blockT-minimum {
  margin-top: $minimum-space;
  @include mq(md, max) {
    margin-top: $minimum-space-mdMax;
  }
}
.blockR-minimum {
  margin-right: $minimum-space;
  @include mq(md, max) {
    margin-right: $minimum-space-mdMax;
  }
}
.blockB-minimum {
  margin-bottom: $minimum-space;
  @include mq(md, max) {
    margin-bottom: $minimum-space-mdMax;
  }
}
.blockL-minimum {
  margin-left: $minimum-space;
  @include mq(md, max) {
    margin-left: $minimum-space-mdMax;
  }
}

$zero-space: 0;
$zero-space-mdMax: 0;
.box-zero {
  margin: $zero-space;
  @include mq(md, max) {
    margin: $zero-space-mdMax;
  }
}
.block-zero {
  margin-top: $zero-space;
  margin-bottom: $zero-space;
  @include mq(md, max) {
    margin-top: $zero-space-mdMax;
    margin-bottom: $zero-space-mdMax;
  }
}
.blockT-zero {
  margin-top: $zero-space;
  @include mq(md, max) {
    margin-top: $zero-space-mdMax;
  }
}
.blockB-zero {
  margin-bottom: $zero-space;
  @include mq(md, max) {
    margin-bottom: $zero-space-mdMax;
  }
}

@include mq(md, min) {
  .box-base-mdMin {
    margin: $base-space;
  }
  .block-base-mdMin {
    margin-top: $base-space;
    margin-bottom: $base-space;
  }
  .blockT-base-mdMin {
    margin-top: $base-space;
  }
  .blockR-base-mdMin {
    margin-right: $base-space;
  }
  .blockB-base-mdMin {
    margin-bottom: $base-space;
  }
  .blockL-base-mdMin {
    margin-left: $base-space;
  }

  .box-section03-mdMin {
    margin: $section03-space;
  }
  .block-section03-mdMin {
    margin-top: $section03-space;
    margin-bottom: $section03-space;
  }
  .blockT-section03-mdMin  {
    margin-top: $section03-space;
  }
  .blockR-section03-mdMin  {
    margin-right: $section03-space;
  }
  .blockB-section03-mdMin  {
    margin-bottom: $section03-space;
  }
  .blockL-section03-mdMin  {
    margin-left: $section03-space;
  }

  .box-section02-mdMin {
    margin: $section02-space;
  }
  .block-section02-mdMin {
    margin-top: $section02-space;
    margin-bottom: $section02-space;
  }
  .blockT-section02-mdMin {
    margin-top: $section02-space;
  }
  .blockR-section02-mdMin {
    margin-right: $section02-space;
  }
  .blockB-section02-mdMin {
    margin-bottom: $section02-space;
  }
  .blockL-section02-mdMin {
    margin-left: $section02-space;
  }

  .box-section-mdMin {
    margin: $section-space;
  }
  .block-section-mdMin {
    margin-top: $section-space;
    margin-bottom: $section-space;
  }
  .blockT-section-mdMin {
    margin-top: $section-space;
  }
  .blockB-section-mdMin {
    margin-bottom: $section-space;
  }
  .blockL-section-mdMin {
    margin-left: $section-space;
  }
  .blockR-section-mdMin {
    margin-right: $section-space;
  }

  .box-contents03-mdMin {
    margin: $contents03-space;
  }
  .block-contents03-mdMin {
    margin-top: $contents03-space;
    margin-bottom: $contents03-space;
  }
  .blockT-contents03-mdMin {
    margin-top: $contents03-space;
  }
  .blockB-contents03-mdMin {
    margin-bottom: $contents03-space;
  }
  .blockL-contents03-mdMin {
    margin-left: $contents03-space;
  }
  .blockR-contents03-mdMin {
    margin-right: $contents03-space;
  }

  .box-contents02-mdMin {
    margin: $contents02-space;
  }
  .block-contents02-mdMin {
    margin-top: $contents02-space;
    margin-bottom: $contents02-space;
  }
  .blockT-contents02-mdMin {
    margin-top: $contents02-space;
  }
  .blockB-contents02-mdMin {
    margin-bottom: $contents02-space;
  }
  .blockL-contents02-mdMin {
    margin-left: $contents02-space;
  }
  .blockR-contents02-mdMin {
    margin-right: $contents02-space;
  }

  .box-contents-mdMin {
    margin: $contents-space;
  }
  .block-contents-mdMin {
    margin-top: $contents-space;
    margin-bottom: $contents-space;
  }
  .blockT-contents-mdMin {
    margin-top: $contents-space;
  }
  .blockR-contents-mdMin {
    margin-right: $contents-space;
  }
  .blockB-contents-mdMin {
    margin-bottom: $contents-space;
  }
  .blockL-contents-mdMin {
    margin-left: $contents-space;
  }

  .box-normal-mdMin {
    margin: $normal-space;
  }
  .block-normal-mdMin {
    margin-top: $normal-space;
    margin-bottom: $normal-space;
  }
  .blockT-normal-mdMin {
    margin-top: $normal-space;
  }
  .blockR-normal-mdMin {
    margin-right: $normal-space;
  }
  .blockB-normal-mdMin {
    margin-bottom: $normal-space;
  }
  .blockL-normal-mdMin {
    margin-left: $normal-space;
  }

  .box-mini-mdMin {
    margin: $mini-space;
  }
  .block-mini-mdMin {
    margin-top: $mini-space;
    margin-bottom: $mini-space;
  }
  .blockT-mini-mdMin {
    margin-top: $mini-space;
  }
  .blockR-mini-mdMin {
    margin-right: $mini-space;
  }
  .blockB-mini-mdMin {
    margin-bottom: $mini-space;
  }
  .blockL-mini-mdMin {
    margin-left: $mini-space;
  }

  .box-semi-mdMin {
    margin: $semi-space;
  }
  .block-semi-mdMin {
    margin-top: $semi-space;
    margin-bottom: $semi-space;
  }
  .blockT-semi-mdMin {
    margin-top: $semi-space;
  }
  .blockR-semi-mdMin {
    margin-right: $semi-space;
  }
  .blockB-semi-mdMin {
    margin-bottom: $semi-space;
  }
  .blockL-semi-mdMin {
    margin-left: $semi-space;
  }

  .box-minimum-mdMin {
    margin: $minimum-space;
  }
  .block-minimum-mdMin {
    margin-top: $minimum-space;
    margin-bottom: $minimum-space;
  }
  .blockT-minimum-mdMin {
    margin-top: $minimum-space;
  }
  .blockR-minimum-mdMin {
    margin-right: $minimum-space;
  }
  .blockB-minimum-mdMin {
    margin-bottom: $minimum-space;
  }
  .blockL-minimum-mdMin {
    margin-left: $minimum-space;
  }

  .box-zero-mdMin {
    margin: $zero-space;
  }
  .block-zero-mdMin {
    margin-top: $zero-space;
    margin-bottom: $zero-space;
  }
  .blockT-zero-mdMin {
    margin-top: $zero-space;
  }
  .blockB-zero-mdMin {
    margin-bottom: $zero-space;
  }
}

@include mq(md, max) {
  .box-base-mdMax {
    margin: $base-space-mdMax;
  }
  .block-base-mdMax {
    margin-top: $base-space-mdMax;
    margin-bottom: $base-space-mdMax;
  }
  .blockT-base-mdMax {
    margin-top: $base-space-mdMax;
  }
  .blockR-base-mdMax {
    margin-right: $base-space-mdMax;
  }
  .blockB-base-mdMax {
    margin-bottom: $base-space-mdMax;
  }
  .blockL-base-mdMax {
    margin-left: $base-space-mdMax;
  }

  .box-section03-mdMax {
    margin: $section03-space-mdMax;
  }
  .block-section03-mdMax {
    margin-top: $section03-space-mdMax;
    margin-bottom: $section03-space-mdMax;
  }
  .blockT-section03-mdMax {
    margin-top: $section03-space-mdMax;
  }
  .blockR-section03-mdMax {
    margin-right: $section03-space-mdMax;
  }
  .blockB-section03-mdMax {
    margin-bottom: $section03-space-mdMax;
  }
  .blockL-section03-mdMax {
    margin-left: $section03-space-mdMax;
  }

  .box-section02-mdMax {
    margin: $section02-space-mdMax;
  }
  .block-section02-mdMax {
    margin-top: $section02-space-mdMax;
    margin-bottom: $section02-space-mdMax;
  }
  .blockT-section02-mdMax {
    margin-top: $section02-space-mdMax;
  }
  .blockR-section02-mdMax {
    margin-right: $section02-space-mdMax;
  }
  .blockB-section02-mdMax {
    margin-bottom: $section02-space-mdMax;
  }
  .blockL-section02-mdMax {
    margin-left: $section02-space-mdMax;
  }

  .box-section-mdMax {
    margin: $section-space-mdMax;
  }
  .block-section-mdMax {
    margin-top: $section-space-mdMax;
    margin-bottom: $section-space-mdMax;
  }
  .blockT-section-mdMax {
    margin-top: $section-space-mdMax;
  }
  .blockR-section-mdMax {
    margin-right: $section-space-mdMax;
  }
  .blockB-section-mdMax {
    margin-bottom: $section-space-mdMax;
  }
  .blockL-section-mdMax {
    margin-left: $section-space-mdMax;
  }

  .box-contents03-mdMax {
    margin: $contents03-space-mdMax;
  }
  .block-contents03-mdMax {
    margin-top: $contents03-space-mdMax;
    margin-bottom: $contents03-space-mdMax;
  }
  .blockT-contents03-mdMax {
    margin-top: $contents03-space-mdMax;
  }
  .blockR-contents03-mdMax  {
    margin-right: $contents03-space-mdMax;
  }
  .blockB-contents03-mdMax  {
    margin-bottom: $contents03-space-mdMax;
  }
  .blockL-contents03-mdMax  {
    margin-left: $contents03-space-mdMax;
  }

  .box-contents02-mdMax {
    margin: $contents02-space-mdMax;
  }
  .block-contents02-mdMax {
    margin-top: $contents02-space-mdMax;
    margin-bottom: $contents02-space-mdMax;
  }
  .blockT-contents02-mdMax {
    margin-top: $contents02-space-mdMax;
  }
  .blockR-contents02-mdMax  {
    margin-right: $contents02-space-mdMax;
  }
  .blockB-contents02-mdMax  {
    margin-bottom: $contents02-space-mdMax;
  }
  .blockL-contents02-mdMax  {
    margin-left: $contents02-space-mdMax;
  }

  .box-contents-mdMax {
    margin: $contents-space-mdMax;
  }
  .block-contents-mdMax {
    margin-top: $contents-space-mdMax;
    margin-bottom: $contents-space-mdMax;
  }
  .blockT-contents-mdMax {
    margin-top: $contents-space-mdMax;
  }
  .blockR-contents-mdMax  {
    margin-right: $contents-space-mdMax;
  }
  .blockB-contents-mdMax  {
    margin-bottom: $contents-space-mdMax;
  }
  .blockL-contents-mdMax  {
    margin-left: $contents-space-mdMax;
  }

  .box-normal-mdMax {
    margin: $normal-space-mdMax;
  }
  .block-normal-mdMax {
    margin-top: $normal-space-mdMax;
    margin-bottom: $normal-space-mdMax;
  }
  .blockT-normal-mdMax {
    margin-top: $normal-space-mdMax;
  }
  .blockR-normal-mdMax {
    margin-right: $normal-space-mdMax;
  }
  .blockB-normal-mdMax {
    margin-bottom: $normal-space-mdMax;
  }
  .blockL-normal-mdMax {
    margin-left: $normal-space-mdMax;
  }

  .box-semi-mdMax {
    margin: $semi-space-mdMax;
  }
  .block-semi-mdMax {
    margin-top: $semi-space-mdMax;
    margin-bottom: $semi-space-mdMax;
  }
  .blockT-semi-mdMax {
    margin-top: $semi-space-mdMax;
  }
  .blockR-semi-mdMax {
    margin-right: $semi-space-mdMax;
  }
  .blockB-semi-mdMax {
    margin-bottom: $semi-space-mdMax;
  }
  .blockL-semi-mdMax {
    margin-left: $semi-space-mdMax;
  }

  .box-mini-mdMax {
    margin: $mini-space-mdMax;
  }
  .block-mini-mdMax {
    margin-top: $mini-space-mdMax;
    margin-bottom: $mini-space-mdMax;
  }
  .blockT-mini-mdMax {
    margin-top: $mini-space-mdMax;
  }
  .blockR-mini-mdMax {
    margin-right: $mini-space-mdMax;
  }
  .blockB-mini-mdMax {
    margin-bottom: $mini-space-mdMax;
  }
  .blockL-mini-mdMax {
    margin-left: $mini-space-mdMax;
  }

  .box-minimum-mdMax {
    margin: $minimum-space-mdMax;
  }
  .block-minimum-mdMax {
    margin-top: $minimum-space-mdMax;
    margin-bottom: $minimum-space-mdMax;
  }
  .blockT-minimum-mdMax  {
    margin-top: $minimum-space-mdMax;
  }
  .blockR-minimum-mdMax  {
    margin-right: $minimum-space-mdMax;
  }
  .blockB-minimum-mdMax  {
    margin-bottom: $minimum-space-mdMax;
  }
  .blockL-minimum-mdMax  {
    margin-left: $minimum-space-mdMax;
  }

  .box-zero-mdMax {
    margin: $zero-space-mdMax;
  }
  .block-zero-mdMax {
    margin-top: $zero-space-mdMax;
    margin-bottom: $zero-space-mdMax;
  }
  .blockT-zero-mdMax {
    margin-top: $zero-space-mdMax;
  }
  .blockB-zero-mdMax {
    margin-bottom: $zero-space-mdMax;
  }
}

@charset "utf-8";

.rollOver {
  transition: opacity $transition-time-base;
  img {
    background-color: rgba(255, 255, 255, .01);
  }
  &:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}
.link {
  &:hover {
    text-decoration: underline;
  }
}
.linkText {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

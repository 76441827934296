@charset "utf-8";

//font size
$root-fontSize: 16;

//font family
//$font-english: 'Roboto', sans-serif;
//$font-english-serif: "Noto Serif JP", serif;
$font-mincho: "Noto Serif JP", "游明朝", "Yu Mincho", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
$font-gothic: "Roboto", "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", "Meiryo, Osaka", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

//color
$color-base: #b60000;
//$color-base-light: #FFEEEE;
$color-black: #000; //
$color-main-text: $color-black; //
$color-black-light: #333; //
$color-white: #fff; //
$color-gray: #ccc; //
$color-gray02: #777; //
$color-gray-light: #F3F3F3; //
$color-gray-light02: #eee; //
$color-gray-light03: #ddd; //
$color-red: $color-base;
$color-yellow: #ffff00; //
$color-yellow02: #FFC926;
$color-beige: #f4f2ed;//
$color-beige-light: #FDFCFB;//

//color SNS
$color-twitter: rgb(29,161,242); //
$color-instagram: #b900b4; //
$color-facebook: #385898; //

//Header Height
$header-height: 120px;
$header-height-mdMax: 50px;
$header-scroll_space: 60px;
$header-innerHeight: $header-height - $header-scroll_space;

//Footer Nav Height //$footer-height-sp: 60px;

//スペースの基本（ブラウザの余白など）
$space-unit: 20px;
$space-unit-mdMax: 20px;
$space-unit-lgMax: 20px;
//カラムやボックスなどで使用するマージンの基本
$column-space: 40px;
$column-space-mdMax: 20px;
$column-space-lgMax: $column-space-mdMax;
//基本的なカラムの幅
$column-width: 1120px;//
$column-width-short: 800px;//
$column-width-long: 1320px;//
$column-width-max: 1920px;//カラム幅の最大値

//基本的なカラムの幅に余白を追加
$base-content-width: $column-width + $space-unit*2; //
$base-content-width-short: $column-width-short + $space-unit*2;
$base-content-width-long: $column-width-long + $space-unit*2;

//scroll bar
$scroll-bar: 17px; //17pxと仮定

//line-height
$line-height: 1.8; //
$line-height-low: $line-height*.756; //
$line-height-middle: $line-height*.867; //
$line-height-high: $line-height*1.111; //
$line-height-highest: $line-height*1.667; //

//transition-time
$transition-time-base: .3s;

//paths
$image-path: "../images/";
$font-path: "../fonts/";
//animationClass
$class-active: ".is-active";
$class-active-mdMin: ".is-active-mdMin";
$class-active-mdMax: ".is-active-mdMax";
$class-current: ".is-current";
$class-fixed: ".is-fixed";
$class-show: ".is-show";
$class-start: ".is-start";
$class-scroll-mdMin: ".is-scroll-mdMin";
$data-anime: "[data-animation="true"]";

@charset "utf-8";

.ItemPanel {
  $root: &;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: opacity 0.3s;
  @include mq(md, min) {
    margin-bottom: 0.625rem;
    margin-right: calc(1.875rem / 3);
    width: calc((100% - 1.875rem) / 4);
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
  @include mq(md, max) {
    margin-bottom: 4vw;
    margin-right: 4vw;
    width: 44vw;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  &:hover {
    opacity: 0.8;
    .btn {
      background-color: #ec6713 !important;
    }
  }
  &__inner {
    position: relative;
    background-color: #fff;
  }
  &__img {
  }
  &__icon {
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    @include mq(md, max) {
      top: 1.33333vw;
      right: 1.33333vw;
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 48px;
      margin-left: 5px;
      border-radius: 100%;
      background-color: #ec6713;
      @include mq(md, max) {
        height: 8.53333vw;
        width: 8.53333vw;
      }
      &--other {
        background-color: #bbc7ae;
      }
    }
    &__text {
      font-size: 10px;
      line-height: 1.2;
      text-align: center;
      @include mq(md, max) {
        font-size: 1.86667vw;
      }
      &--other {
        font-weight: 700;
        font-size: 12px;
        @include mq(md, max) {
          font-size: 2.1vw;
        }
      }
    }
    &__bold {
      font-size: 16px;
      font-weight: bold;
      @include mq(md, max) {
        font-size: 3.2vw;
      }
    }
  }
  &__label {
    @include mq(md, min) {
      padding: 0 1.25rem 1.25rem;
      font-size: 1rem;
    }
    @include mq(md, max) {
      padding: 0 2.66667vw 2.66667vw;
      font-size: 3.2vw;
    }
  }
}

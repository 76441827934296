@charset "utf-8";

.LineupPanelIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  @include mq(md, max) {
    top: 1.33333vw;
    right: 1.33333vw;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    width: 52px;
    margin-bottom: 5px;
    border-radius: 100%;
    background-color: #ec6713;
    @include mq(md, max) {
      height: 8.53333vw;
      width: 8.53333vw;
    }
    &--other {
      background-color: #bbc7ae;
    }
  }
  &__text {
    font-size: 10px;
    line-height: 1.2;
    text-align: center;
    @include mq(md, max) {
      font-size: 1.86667vw;
    }
    &--other {
      font-weight: 700;
      font-size: 12px;
      @include mq(md, max) {
        font-size: 2.1vw;
      }
    }
  }
}

@charset "utf-8";

.cf {
  @include cf;
}
.cf-mdMin {
  @include mq(md, min) {
    @include cf;
  }
}
.cf-mdMax {
  @include mq(md, max) {
    @include cf;
  }
}

@charset "utf-8";

.disNone {
  display: none;
}
.disNone-mdMin {
  @include mq(md, min) {
    display: none;
  }
}
.disNone-mdMax {
  @include mq(md, max) {
    display: none;
  }
}
.disNone-active {
  &.is-active {
    display: none;
  }
}

//ユーザーエージェントで判別
.pcNone {
  @include pc {
    display: none;
  }
}
.spNone {
  @include sp {
    display: none;
  }
}
.tabNone {
  @include tab {
    display: none;
  }
}

//.tabOnly {
//  @include pc {
//    display: none;
//  }
//  @include sp {
//    display: none;
//  }
//}
